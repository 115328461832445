import React from "react";
import {
  IonItem,
  IonLabel
} from "@ionic/react";
import Algorithm from "../../types/algorithm";
import "./styles.css";

interface ListItemProps {
  item: Algorithm;
  highlight: boolean;
}

const ListItem: React.FC<ListItemProps> = ({ item, highlight }) => {
  return (
    <IonItem
      routerLink={`/run/${item.id}`}
      lines="full"
      detail={false}
      className="list"
    >
      <div slot="start" className={highlight ? "dot dot-highlight" : "dot"} />
      <IonLabel className="ion-text-wrap list" >
        {item.name}
      </IonLabel>
    </IonItem>
  );
};

export default ListItem;
