import React from "react";
import { connect } from "react-redux";
import { IonButton, IonItem, IonList } from "@ionic/react";
import { clearSelection, toggleSelected } from "../../actions/ui";
import { getLanguageText } from "../../Language";
import Item from "./Item";

interface OwnProps {
  data: any[];
  exclusive: boolean;
  onDone: (id?: string) => any;
  searchText?: string;
}

interface ReduxProps {
  ui: any;
  clearSelection: () => any;
  toggleSelected: (id: string) => any;
}

interface OptionsProps extends OwnProps, ReduxProps { }

const Options: React.FC<OptionsProps> = ({
  data, exclusive, onDone, searchText, ui, clearSelection, toggleSelected
}) => {

  const { language, selectedOptions } = ui;

  const _onSelect = (id: string, exclusiveItem: boolean) => {
    if (exclusiveItem) {
      // if exclusive option, clear others
      clearSelection();
    }
    toggleSelected(id);
    if (exclusive || 1 === data.length) {
      onDone(id);
    }
  };

  return (
    <IonList>
      <IonItem lines="full" />
      {data.map((item, i) => (
        <Item
          key={item.id}
          index={i}
          data={item}
          selected={!!selectedOptions[item.id]}
          searchText={searchText}
          onPress={_onSelect}
        />
      ))}
      {!exclusive && data.length > 1 &&
        <IonButton expand="block" onClick={() => onDone()}>
          {getLanguageText(language, "submit")}
        </IonButton>
      }
      <IonItem lines="none" />
    </IonList>
  );
};

const mapStateToProps = (state: any) => ({
  ui: state.ui
});

export default connect(mapStateToProps, { clearSelection, toggleSelected })(Options);
