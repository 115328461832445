export const UI_SET_DATA = "UI_SET_DATA";
export const setUiData = (key: string, value: any) => ({
  type: UI_SET_DATA,
  key,
  value,
});

export const UI_SET_VISITED = "UI_SET_VISITED";
export const setVisited = (id: string) => ({
  type: UI_SET_VISITED,
  id,
});

export const UI_TOGGLE_SELECTED = "UI_TOGGLE_SELECTED";
export const toggleSelected = (id: string) => ({
  type: UI_TOGGLE_SELECTED,
  id,
});

export const UI_CLEAR_SELECTION = "UI_CLEAR_SELECTION";
export const clearSelection = () => ({
  type: UI_CLEAR_SELECTION,
});

export const UI_QUESTION_ASKED = "UI_QUESTION_ASKED";
export const questionAsked = (id: string) => ({
  type: UI_QUESTION_ASKED,
  id,
});

export const setLoading = (value: boolean) => ({
  type: UI_SET_DATA,
  key: "loading",
  value,
});

export const UI_SAVE_PREV = "UI_SAVE_PREV";
export const saveUiPrev = () => ({
  type: UI_SAVE_PREV,
});

export const UI_RETRIEVE_PREV = "UI_RETRIEVE_PREV";
export const goUiPrev = () => ({
  type: UI_RETRIEVE_PREV,
});

export const UI_CLEAR = "UI_CLEAR";
export const clearUi = () => ({
  type: UI_CLEAR,
});
