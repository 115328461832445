import React from "react";
import { IonFooter, IonTitle } from "@ionic/react";

export default () => {
  return (
    <IonFooter>
      <IonTitle
        size="small"
        color="tertiary"
        onClick={() => window.open("https://mayamd.ai", "_system")}
      >
        © MayaMD.ai
      </IonTitle>
    </IonFooter>
  );
};
