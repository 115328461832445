export const INPUT_SET_DATA = "INPUT_SET_DATA";
export const setInputData = (key: string, value: any) => ({
  type: INPUT_SET_DATA,
  key,
  value,
});

export const INPUT_ADD_SYMPTOM = "INPUT_ADD_SYMPTOM";
export const addSymptom = (id: string) => ({
  type: INPUT_ADD_SYMPTOM,
  symptom: { id },
});

export const INPUT_ADD_ANSWER = "INPUT_ADD_ANSWER";
export const addAnswer = (questionAnswer: any) => ({
  type: INPUT_ADD_ANSWER,
  questionAnswer,
});

export const INPUT_SAVE_PREV = "INPUT_SAVE_PREV";
export const saveInputPrev = () => ({
  type: INPUT_SAVE_PREV,
});

export const INPUT_RETRIEVE_PREV = "INPUT_RETRIEVE_PREV";
export const goInputPrev = () => ({
  type: INPUT_RETRIEVE_PREV,
});

export const INPUT_CLEAR = "INPUT_CLEAR";
export const clearInput = () => ({
  type: INPUT_CLEAR,
});
