import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import config from "../config";
import api, { socketApi } from "../middleware/api";
import rootReducer from "../reducers";

const store =
  "development" === config.mode
    ? createStore(
        rootReducer,
        // Also apply our middleware with devtools
        composeWithDevTools(applyMiddleware(thunk, api, socketApi))
      )
    : createStore(
        rootReducer,
        // Also apply our middleware
        applyMiddleware(thunk, api, socketApi)
      );

export default store;
