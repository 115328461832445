import React from "react";
import {
  IonCard,
  IonCardContent
} from "@ionic/react";
import "./styles.css";

interface BubbleProps {
  text: string;
  type: string;
  rightToLeft?: boolean;
}

const Bubble: React.FC<BubbleProps> = ({ text, type, rightToLeft }) => {
  return (
    <IonCard className={type + (rightToLeft ? "rtl" : "ltr")}>
      <IonCardContent>
        {text}
      </IonCardContent>
    </IonCard>
  );
};

export default Bubble;
