import React from "react";
import {
  IonItem,
  IonLabel,
  IonIcon,
  IonButton
} from "@ionic/react";
import { informationCircle } from "ionicons/icons";

interface ItemProps {
  index: number;
  data: any
  selected: boolean;
  searchText?: string;
  onPress: (id: string, exclusiveItem: boolean) => any;
}

const Item: React.FC<ItemProps> = ({ data, selected, onPress, searchText }) => {
  const { id, name, exclusive } = data;

  return (
    <IonItem
      lines="full"
      detail={false}
      color={selected ? "secondary" : undefined}
      button
      onClick={() => onPress(id, exclusive)}
    >
      <IonLabel className="ion-text-wrap">
        {name}
      </IonLabel>
      {!!searchText &&
        <IonButton
          slot="end"
          fill="clear"
          shape="round"
          size="default"
          onClick={e => {
            console.info("info clicked");
            e.stopPropagation();
          }}
        >
          <IonIcon slot="icon-only" icon={informationCircle} />
        </IonButton>
      }
    </IonItem>
  );
};

export default Item;
