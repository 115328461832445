import ListItem from "../../components/ListItem";
import React, { useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import {
  IonContent,
  IonHeader,
  IonList,
  IonLoading,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonSearchbar,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter
} from "@ionic/react";
import Algorithm from "../../types/algorithm";
import { getAlgorithms } from "../../actions/ws";
import { getLanguageText } from "../../Language";
import Footer from "../../components/Footer";
import "./styles.css";

interface OwnProps extends RouteComponentProps { }

interface ReduxProps {
  ui: any;
  algorithms: [Algorithm];
  getAlgorithms: (callBack?: (store: any) => any) => any;
}

interface HomeProps extends OwnProps, ReduxProps { }

const Home: React.FC<HomeProps> = ({ ui, algorithms, getAlgorithms }) => {

  const { language, loading, visitedAlgorithms } = ui,
    [searchText, setSearchText] = useState("");

  useIonViewWillEnter(() => {
    setSearchText("");
    getAlgorithms();
  });

  const refresh = (e: CustomEvent) => {
    getAlgorithms(e.detail.complete);
  };

  return (
    <IonPage id="home-page">
      <IonHeader>
        <IonToolbar>
          <IonTitle>{getLanguageText(language, "homeTitle")}</IonTitle>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar
            placeholder={getLanguageText(language, "search")}
            value={searchText}
            onIonChange={e => setSearchText(e.detail.value!)}
          />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={refresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">
              {getLanguageText(language, "homeTitle")}
            </IonTitle>
          </IonToolbar>
        </IonHeader>

        {!loading &&
          <IonList>
            {searchText
              ? algorithms
                .filter(a => -1 !== a.name.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()))
                .map((a, i) => <ListItem key={i} item={a} highlight={!!visitedAlgorithms[a.id]} />)
              : algorithms
                .map((a, i) => <ListItem key={i} item={a} highlight={!!visitedAlgorithms[a.id]} />)}
          </IonList>
        }
        <IonLoading isOpen={loading} />
      </IonContent>
      <Footer />
    </IonPage>
  );
};

const mapStateToProps = (state: any) => ({
  ui: state.ui,
  algorithms: state.ws.algorithms
});

export default connect(mapStateToProps, { getAlgorithms })(Home);
