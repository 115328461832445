import React from "react";
import { Redirect, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { IonApp, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter, } from "@ionic/react-router";
import store from "./store";
import Home from "./pages/Home";
import RunAlgo from "./pages/RunAlgo";
import config from "./config";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

const App: React.FC = () => {

  const hostname = window.location.hostname, appConfig = config.hostnameMap[hostname];

  console.info("Hostname:", hostname);
  console.info("appConfig:", appConfig);
  if (appConfig) {
    document.title = appConfig.appName;
  }

  return (
    <Provider store={store}>
      <IonApp>
        <IonReactRouter>
          {!!appConfig &&
            <IonRouterOutlet>
              <Route exact path="/" component={RunAlgo} />
              <Redirect to="/" />
            </IonRouterOutlet>
          }
          {!appConfig &&
            <IonRouterOutlet>
              <Route exact path="/" component={Home} />
              <Route exact path="/run/:id" component={RunAlgo} />
              <Redirect to="/" />
            </IonRouterOutlet>
          }
        </IonReactRouter>
      </IonApp>
    </Provider>
  );
};

export default App;
