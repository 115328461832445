import Config from "./types/config";

const config: Config = {
  mode: "production",
  debugAlgo: false,
  apiUrl: "https://ws1.mayamd.ai/",
  apiVersion: "v6",
  defaultAppConfig: {
    appName: "Maya Algo",
    algorithm: "",
  },
  hostnameMap: {
    "corona.mayamd.ai": { appName: "Corona Virus", algorithm: "16677" },
    "covid.mayamd.ai": { appName: "Corona Virus", algorithm: "16677" },
    "covid19.mayamd.ai": { appName: "Corona Virus", algorithm: "16677" },
    "covidguide.mayamd.ai": { appName: "HCQ-Z Pak guide", algorithm: "16681" },
    "covidkerala.mayamd.ai": { appName: "കോവിഡ് 19", algorithm: "16679" },
    "covidscreening.mayamd.ai": {
      appName: "Covid Screening",
      algorithm: "16680",
    },
    "preop.mayamd.ai": {
      appName: "Pre operative evaluation for non-cardiac surgery",
      algorithm: "9874",
    },
    "pe.mayamd.ai": {
      appName: "Appropriateness criteria Suspected PE",
      algorithm: "12828",
    },
    "covidacls.mayamd.ai": {
      appName: "ACLS: Cardiac arrest for COVID",
      algorithm: "16685",
    },
    "menoctyl.mayamd.ai": {
      appName: "Menoctyl: Otolinium bromide for IBS overview",
      algorithm: "16687",
    },
    "vaccination.mayamd.ai": {
      appName: "Vaccination recommendations",
      algorithm: "16693",
    },
  },
  socketRequestTimeout: 10000 /* milliseconds */,
  termsLink: "https://mayamd.ai/terms-and-conditions/index.html",
  agreementLink: "https://mayamd.ai/user-agreement/index.html",
  privacyLink: "https://mayamd.ai/privacy-policy/index.html",
};

export default config;
