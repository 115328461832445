import React from "react";
import {
  IonItem,
  IonLabel
} from "@ionic/react";
import Inference from "../types/inference";

interface InferenceItemProps {
  inference: Inference;
}

const InferenceItem: React.FC<InferenceItemProps> = ({ inference }) => {
  const clickable = !!inference.link;

  return (
    <IonItem
      lines="full"
      button={clickable}
      detail={clickable}
      onClick={() => (clickable && window.open(inference.link, "_system"))}
    >
      <IonLabel className="ion-text-wrap">
        <h3>{inference.name}</h3>
        {!!inference.reference &&
          <p>{inference.reference}</p>
        }
      </IonLabel>
    </IonItem>
  );
};

export default InferenceItem;
