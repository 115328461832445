import { CALL_SOCKET_API } from "../middleware/const";

export const WS_GET_ALGORITHMS_REQUEST = "WS_GET_ALGORITHMS_REQUEST";
export const WS_GET_ALGORITHMS_SUCCESS = "WS_GET_ALGORITHMS_SUCCESS";
export const WS_GET_ALGORITHMS_FAILURE = "WS_GET_ALGORITHMS_FAILURE";
export const getAlgorithms = (callBack?: (store: any) => any) => ({
  [CALL_SOCKET_API]: {
    types: [
      WS_GET_ALGORITHMS_REQUEST,
      WS_GET_ALGORITHMS_SUCCESS,
      WS_GET_ALGORITHMS_FAILURE,
    ],
    endpoint: "getAlgorithmicSymptoms",
    data: {},
    callBack,
  },
});

export const WS_GET_ALGORITHM_REQUEST = "WS_GET_ALGORITHM_REQUEST";
export const WS_GET_ALGORITHM_SUCCESS = "WS_GET_ALGORITHM_SUCCESS";
export const WS_GET_ALGORITHM_FAILURE = "WS_GET_ALGORITHM_FAILURE";
export const getAlgorithm = (id: string, callBack?: (store: any) => any) => ({
  [CALL_SOCKET_API]: {
    types: [
      WS_GET_ALGORITHM_REQUEST,
      WS_GET_ALGORITHM_SUCCESS,
      WS_GET_ALGORITHM_FAILURE,
    ],
    endpoint: "getAlgorithmicSymptom",
    data: { id },
    callBack,
  },
});

export const WS_SET_DATA = "WS_SET_DATA";
export const setWsData = (key: string, value: any) => ({
  type: WS_SET_DATA,
  key,
  value,
});

export const setStatusMessage = (
  message: string,
  mode: string,
  timeout?: number,
  details?: string
) => ({
  type: "SET_STATUS",
  message,
  mode,
  timeout,
  details,
});
export const resetStatusMessage = () => ({ type: "RESET_STATUS" });

export const WS_PROCESS_INPUT_REQUEST = "WS_PROCESS_INPUT_REQUEST";
export const WS_PROCESS_INPUT_SUCCESS = "WS_PROCESS_INPUT_SUCCESS";
export const WS_PROCESS_INPUT_FAILURE = "WS_PROCESS_INPUT_FAILURE";
export const processInput = (input: any, callBack?: (store: any) => any) => ({
  [CALL_SOCKET_API]: {
    types: [
      WS_PROCESS_INPUT_REQUEST,
      WS_PROCESS_INPUT_SUCCESS,
      WS_PROCESS_INPUT_FAILURE,
    ],
    endpoint: "processInput",
    nonBlocking: true,
    data: { input },
    callBack,
  },
});

export const WS_GET_QUESTION_REQUEST = "WS_GET_QUESTION_REQUEST";
export const WS_GET_QUESTION_SUCCESS = "WS_GET_QUESTION_SUCCESS";
export const WS_GET_QUESTION_FAILURE = "WS_GET_QUESTION_FAILURE";
export const getQuestion = (ids: string[], callBack?: (store: any) => any) => ({
  [CALL_SOCKET_API]: {
    types: [
      WS_GET_QUESTION_REQUEST,
      WS_GET_QUESTION_SUCCESS,
      WS_GET_QUESTION_FAILURE,
    ],
    endpoint: "getQuestion",
    nonBlocking: true,
    data: { ids },
    callBack,
  },
});

export const WS_SAVE_PREV = "WS_SAVE_PREV";
export const saveWsPrev = () => ({
  type: WS_SAVE_PREV,
});

export const WS_RETRIEVE_PREV = "WS_RETRIEVE_PREV";
export const goWsPrev = () => ({
  type: WS_RETRIEVE_PREV,
});

export const WS_CLEAR = "WS_CLEAR";
export const clearWs = () => ({
  type: WS_CLEAR,
});
