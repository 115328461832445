import {
  UI_SET_DATA,
  UI_SET_VISITED,
  UI_TOGGLE_SELECTED,
  UI_CLEAR_SELECTION,
  UI_QUESTION_ASKED,
  UI_SAVE_PREV,
  UI_RETRIEVE_PREV,
  UI_CLEAR,
} from "../actions/ui";
import { getInitialReducerState } from "../lib/utils";

const initial = {
  loading: false,
  language: "",
  rightToLeft: false,
  evaluationId: "",
  visitedAlgorithms: {},
  selectedOptions: {},
  askedQuestions: [],
  prevState: null,
  noAnswer: false,
  showInference: false,
};

const ui = (state = getInitialReducerState(initial), action: any) => {
  const { type, id, key, value } = action;
  switch (type) {
    case UI_SET_DATA:
      return { ...state, [key]: value };
    case UI_SET_VISITED:
      return {
        ...state,
        visitedAlgorithms: {
          ...state.visitedAlgorithms,
          [id]: true,
        },
      };
    case UI_TOGGLE_SELECTED:
      return {
        ...state,
        selectedOptions: {
          ...state.selectedOptions,
          [id]: !state.selectedOptions[id],
        },
      };
    case UI_CLEAR_SELECTION:
      return {
        ...state,
        selectedOptions: {},
      };
    case UI_QUESTION_ASKED:
      return {
        ...state,
        askedQuestions: [...state.askedQuestions, id],
      };
    case UI_SAVE_PREV:
      return {
        ...state,
        prevState: { ...state },
      };
    case UI_RETRIEVE_PREV:
      return state.prevState ? state.prevState : state;
    case UI_CLEAR:
      // preserve language setting and visited
      return {
        ...getInitialReducerState(initial),
        rightToLeft: state.rightToLeft,
        language: state.language,
        visitedAlgorithms: state.visitedAlgorithms,
      };
    default:
      return state;
  }
};

export default ui;

export const getSymptomStateIndex = (state: any) => {
  return state.symptomIndex - 1;
};

export const getPreviousState = (state: any) => {
  return state.prevState;
};
