import io from "socket.io-client";
import { encode as btoa, decode as atob } from "base-64";
import { deflate, inflate } from "pako";
import config from "../config";
import { CALL_HTTP_API, CALL_SOCKET_API } from "./const";
import {
  setWsData,
  setStatusMessage,
  resetStatusMessage,
  setLoading,
} from "../actions";

const API_ROOT = config.apiUrl,
  socketRequestTimers: any = {},
  socketRequestPromises: any = {},
  apiSequences: any = {},
  autoCompleteActions: any = {
    searchSymptom: true,
    searchContext: true,
    searchMedication: true,
    searchPastMedicalHistory: true,
    searchSurgery: true,
  };
let socket: any = null,
  socketConnected = false,
  socketInitialConnected = false,
  socketApiQueuedRequests: any[] = [];

const logout = () => {
  if (socket) {
    socket.off();
    socket.close();
    socket = null;
    socketConnected = false;
    socketInitialConnected = false;
  }
};

// Fetches an HTTP API response.
const callHttpApi = (
  endpoint: any,
  data: any,
  method = "POST",
  headers = {}
) => {
  const fullUrl =
    endpoint.indexOf("://") === -1 ? API_ROOT + endpoint : endpoint;

  console.log("HTTP Request:", endpoint, method, headers);
  return new Promise((resolve, reject) => {
    fetch(fullUrl, {
      headers: {
        ...headers,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: method,
      body: data ? JSON.stringify(data) : undefined,
    })
      .then((response) => {
        console.log("HTTP response:", response);
        response.json().then((json) => {
          console.log("JSON response:", json);
          if (json.ok) {
            resolve(json);
          } else {
            reject(json);
          }
        });
      })
      .catch((error) => reject(error));
  });
};

// A Redux middleware that interprets actions with CALL_HTTP_API info specified.
// Performs the call and promises when such actions are dispatched.
export default (store: any) => (next: any) => (action: any) => {
  const callHttpAPI = action[CALL_HTTP_API];
  if (typeof callHttpAPI === "undefined") {
    return next(action);
  }

  let {
    endpoint,
    data,
    method,
    headers,
    callBack,
    successMessage,
    nonBlocking,
  } = callHttpAPI;
  const { types } = callHttpAPI;

  if (typeof endpoint === "function") {
    endpoint = endpoint(store.getState());
  }

  if (typeof endpoint !== "string") {
    throw new Error("Specify a string endpoint URL.");
  }
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error("Expected an array of three action types.");
  }
  if (!types.every((type) => typeof type === "string")) {
    throw new Error("Expected action types to be strings.");
  }

  const actionWith = (data: any) => {
    const finalAction = Object.assign({}, action, data);
    delete finalAction[CALL_HTTP_API];
    return finalAction;
  };

  const [requestType, successType, failureType] = types;

  next(actionWith({ type: requestType }));
  if (!nonBlocking) {
    next(setLoading(true));
  }

  return callHttpApi(endpoint, data, method, headers).then(
    (response: any) => {
      console.log("HTTP API response:", response);
      if (response.ok) {
        next(
          actionWith({ type: successType, response, resetStatusMessage: true })
        );
        if (callBack) {
          // Delay the callback a bit so that the success action gets reflected in the state
          setTimeout(() => callBack(store), 100);
        }
        if (successMessage) {
          store.dispatch(setStatusMessage(successMessage, "success", 10));
        }
      } else {
        next(
          actionWith({
            type: failureType,
            error: response.message || "Invalid error",
          })
        );
      }
      if (!nonBlocking) {
        // do this last so that the app will re-render with the new uri location
        next(setLoading(false));
      }
    },
    (error) => {
      console.log("HTTP API error:", error);
      next(
        actionWith({
          type: failureType,
          error: error.message || "Something bad happened",
        })
      );
    }
  );
};

export const connectSocket = (store: any) => {
  socket = io(API_ROOT + "maya-api", {
    transports: ["websocket"],
  });
  attachEvents(socket, store);
};

// Call a SOCKET API action.
const callSocketApi = (action: any, data: any, store: any) => {
  if (apiSequences[action]) {
    apiSequences[action]++;
  } else {
    apiSequences[action] = 1;
  }
  if (!socketRequestPromises[action]) {
    socketRequestPromises[action] = {};
  }
  return new Promise((resolve, reject) => {
    const sequence = apiSequences[action];
    if (socketRequestTimers[action]) {
      const timer = socketRequestTimers[action],
        promise = socketRequestPromises[action][timer.sequence];
      // Subsequent request, clear previous timer and reject it's promise as stale
      clearTimeout(timer.timeout);
      if (promise) {
        delete socketRequestPromises[action][timer.sequence];
        // when a new character is pressed which initiates a new search, cancel the timeout for the
        // previous search but do not cancel the request as such because those result can give some
        // interactivity to the user while the next search is in progress
        if (!autoCompleteActions[action]) {
          promise.reject({
            type: "stale",
            sequence: timer.sequence,
            message: "Newer request sent",
          });
        }
      }
    }
    socketRequestTimers[action] = {
      sequence,
      timeout: setTimeout(() => {
        delete socketRequestTimers[action];
        delete socketRequestPromises[action][sequence];
        if (apiSequences[action] > sequence) {
          console.warn(`${action} old request timed out: ${sequence}`);
          reject({ type: "stale", sequence, message: "Old request timed out" });
        } else {
          console.warn(`${action} request timed out: ${sequence}`);
          reject({ message: `${action} request timed out` });
        }
      }, config.socketRequestTimeout),
    };
    socketRequestPromises[action][sequence] = { resolve, reject };
    if (socket) {
      const params = { ...data },
        state = store.getState();

      params.appName = window.location.hostname;
      params.apiVersion = config.apiVersion;
      params.algorithm = 1;
      params.requestType = action;
      params.requestId = sequence;
      params.evaluationId = state.ui.evaluationId;
      params.language =
        !state.ui.language || "en" === state.ui.language.slice(0, 2)
          ? "default"
          : state.ui.language;
      if (config.debugAlgo) {
        params.debugAlgo = config.debugAlgo;
      }
      console.info("WS Request:", params);
      if ("processInput" === action) {
        store.dispatch(setWsData("processInputCompleted", false));
      }

      const stringOutput = JSON.stringify(params),
        compressed = deflate(stringOutput, { to: "string" }),
        output = btoa(compressed);
      socket.emit("request", output, (ackData: any) => {
        console.info("SOCKET ACK:", ackData);
      });
    } else {
      console.error("No socket connection");
    }
  });
};

const decompressResponse = (response: any) => {
  try {
    const compressed = atob(response),
      decompressed = inflate(compressed, { to: "string" }),
      output = JSON.parse(decompressed);
    return output;
  } catch (error) {
    console.error("Failed to decompress server response", error);
  }
  return false;
};

const handleResponse = (myId: string, response: any, store: any) => {
  console.info("handleResponse:", myId);
  const output = decompressResponse(response);
  console.log("WS OUTPUT:", output);
  if (output) {
    if (output.error) {
      console.error(output);
    } else {
      const { requestType, requestId } = output,
        timer = socketRequestTimers[requestType],
        promise = socketRequestPromises[requestType][requestId];
      if (timer) {
        clearTimeout(timer.timeout);
        delete socketRequestTimers[requestType];
      }
      delete socketRequestPromises[requestType][requestId];
      if (promise) {
        if (requestId === apiSequences[requestType]) {
          promise.resolve(output);
        } else {
          // stale response
          promise.reject({
            type: "stale",
            requestId,
            message: "Stale response",
          });
        }
      }
    }
  } else {
    console.error("Decompression failed:", response);
  }
};

const attachEvents = (socket: any, store: any) => {
  // STANDARD EVENTS
  socket.on("connect", () => {
    console.info("AS CONNECTED:", socket.id);
    socketConnected = true;
    socketInitialConnected = true;
    const queueLength = socketApiQueuedRequests.length;
    if (queueLength) {
      for (let i = 0; i < queueLength; i++) {
        console.info("Dispatch queued request:", socketApiQueuedRequests[i]);
        store.dispatch(socketApiQueuedRequests[i]);
      }
      socketApiQueuedRequests = [];
    }
    store.dispatch(resetStatusMessage());
  });
  socket.on("connect_error", (error: any) => {
    console.error("AS CONNECT FAILED:", error);
    store.dispatch(
      setStatusMessage("Connection failed", "error", 0, error.message)
    );
  });
  socket.on("connect_timeout", (timeout: any) => {
    console.error("AS CONNECTION TIMED OUT:", timeout);
    store.dispatch(setStatusMessage("Connection timed out", "error"));
  });
  socket.on("error", (error: any) => {
    console.error("AS ERROR:", error);
    if ("Unauthorized" === error) {
      // store.dispatch(logoutAgent());
      console.error(error);
    }
    store.dispatch(setStatusMessage(error, "error"));
  });
  socket.on("disconnect", (reason: any) => {
    console.error("AS DISCONNECTED:", reason);
    socketConnected = false;
    store.dispatch(
      setStatusMessage("Disconnected from server", "error", 0, reason)
    );
  });
  socket.on("reconnect", (attemptNumber: any) => {
    console.info("AS RECONNECTED:", attemptNumber);
    socketConnected = true;
    store.dispatch(setStatusMessage("Reconnected to server", "success", 10));
  });
  socket.on("reconnecting", (attemptNumber: any) => {
    console.info("AS RECONNECTING:", attemptNumber);
    store.dispatch(setStatusMessage("Reconnecting to server...", "warning"));
  });
  socket.on("reconnect_error", (error: any) => {
    console.error("AS RECONNECT ERROR:", error);
    store.dispatch(
      setStatusMessage("Reconnect error", "error", 0, error.message)
    );
  });
  socket.on("reconnect_failed", () => {
    console.error("AS RECONNECT FAILED");
    store.dispatch(setStatusMessage("Reconnect failed", "error"));
  });

  // CUSTOM EVENTS
  // Server responses
  socket.on("response", (response: any) =>
    handleResponse(socket.id, response, store)
  );
};

// A Redux middleware that interprets actions with CALL_SOCKET_API info specified.
// Performs the call and promises when such actions are dispatched.
export const socketApi = (store: any) => (next: any) => (action: any) => {
  const callSocketAPI = action[CALL_SOCKET_API];
  if ("undefined" === typeof callSocketAPI) {
    return next(action);
  }

  const {
    endpoint,
    types,
    nonBlocking,
    data,
    successMessage,
    callBack,
  } = callSocketAPI;
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error("Expected an array of three action types.");
  }
  if (!types.every((type) => typeof type === "string")) {
    throw new Error("Expected action types to be strings.");
  }

  const actionWith = (data: any) => {
    const finalAction = Object.assign({}, action, data);
    delete finalAction[CALL_SOCKET_API];
    return finalAction;
  };

  const [requestType, successType, failureType] = types;

  if (!socketConnected) {
    console.warn("No connection to server");
    if ("undefined" !== typeof callSocketAPI) {
      socketApiQueuedRequests.push(action);
      console.info("Request queued");
    }
    // Connect socket if authenticated but not connected
    if (null === socket) {
      connectSocket(store);
    }
    if (socketInitialConnected) {
      next(setStatusMessage("No connection to the server", "error"));
    }
    return;
  }

  next(actionWith({ type: requestType }));
  if (!nonBlocking) {
    next(setLoading(true));
  }
  return callSocketApi(endpoint, data, store).then(
    (response: any) => {
      if (!response.error) {
        next(
          actionWith({ type: successType, response, resetStatusMessage: true })
        );
        if (callBack) {
          // Delay the callback a bit so that the success action gets reflected in the state
          setTimeout(() => callBack(store), 100);
        }
        if ("logoutAgent" === endpoint) {
          logout();
        }
        if (successMessage) {
          store.dispatch(setStatusMessage(successMessage, "success", 10));
        }
      } else {
        next(
          actionWith({
            type: failureType,
            error: response.errorMessage.join() || "Invalid error",
          })
        );
      }
      if (!nonBlocking) {
        // do this last so that the app will re-render with the new uri location
        next(setLoading(false));
      }
    },
    (error) => {
      if ("logoutAgent" === endpoint) {
        // ignore error for logout
        logout();
      } else if ("stale" === error.type) {
        // ignore stale response
        console.warn(
          "Stale response: ",
          endpoint,
          error.sequence,
          error.message
        );
      } else {
        next(
          actionWith({
            type: failureType,
            error: error.message || "Something bad happened",
          })
        );
      }
      if (!nonBlocking) {
        next(setLoading(false));
      }
    }
  );
};
