const text = {
  auto: "Automatic",
  en: "English",
  ar: "Arabic",
  language: "Language",
  search: "Search",
  error: "Error",
  ok: "Ok",
  yes: "Yes",
  no: "No",
  done: "Submit",
  delete: "Delete",
  back: "Back",
  disclaimerTitle: "Disclaimer",
  accept: "Accept",
  terms: "Terms of Use",
  agreement: "User Agreement",
  privacy: "Privacy Notice",
  homeTitle: "Clinical Algorithms",
  loading: "Loading...",
  submit: "Submit",
  alert: "Alert",
  noAnswer: "Select at least one option",
  inferences: "Links & Inferences",
  noQuestions: "No questions",
};

export default text;
