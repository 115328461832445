import { normalize } from "normalizr";
import * as schema from "../middleware/schema";
import {
  WS_GET_ALGORITHMS_SUCCESS,
  WS_SET_DATA,
  WS_GET_ALGORITHM_SUCCESS,
  WS_PROCESS_INPUT_REQUEST,
  WS_PROCESS_INPUT_SUCCESS,
  WS_PROCESS_INPUT_FAILURE,
  WS_GET_QUESTION_SUCCESS,
  WS_SAVE_PREV,
  WS_RETRIEVE_PREV,
  WS_CLEAR,
  WS_GET_QUESTION_FAILURE,
} from "../actions/ws";
import { getInitialReducerState } from "../lib/utils";

const initial = {
  algorithms: [],
  algorithm: null,
  workup: [],
  inferences: [],
  questions: {},
  workupQuestions: [],
  prevState: null,
  error: "",
  processInputCompleted: false,
};

const ws = (state = getInitialReducerState(initial), action: any) => {
  switch (action.type) {
    case WS_GET_ALGORITHMS_SUCCESS:
      return {
        ...state,
        algorithms: action.response.result.sort((a: any, b: any) =>
          a.name.localeCompare(b.name)
        ),
      };
    case WS_SET_DATA:
      const { key, value } = action;
      return { ...state, [key]: value };
    case WS_GET_ALGORITHM_SUCCESS:
      return {
        ...state,
        algorithm: action.response.result,
      };
    case WS_PROCESS_INPUT_REQUEST:
      return {
        ...state,
        error: "",
      };
    case WS_PROCESS_INPUT_SUCCESS:
      const newState = {
        ...state,
        workup: action.response.workup,
      };
      if (action.response.inferences) {
        newState.inferences = action.response.inferences;
      }
      return newState;
    case WS_GET_QUESTION_FAILURE:
      return { ...state };
    case WS_PROCESS_INPUT_FAILURE:
      return { ...state, error: action.error };
    case WS_GET_QUESTION_SUCCESS:
      const normalizedQuestions = normalize(
        action.response.result,
        schema.questions
      );
      return {
        ...state,
        questions: {
          ...state.questions,
          ...normalizedQuestions.entities.questions,
        },
      };
    case WS_SAVE_PREV:
      return {
        ...state,
        prevState: state,
      };
    case WS_RETRIEVE_PREV:
      return state.prevState ? state.prevState : state;
    case WS_CLEAR:
      return getInitialReducerState(initial);
    default:
      return state;
  }
};

export default ws;

export const getQuestions = (state: any, ui: any = null) => {
  let workupQuestions = [...state.workupQuestions];
  if (ui && Object.keys(ui.askedQuestionIds).length > 0) {
    workupQuestions = workupQuestions.filter(
      (row) => !ui.askedQuestionIds[row.id]
    );
  }
  return workupQuestions ? workupQuestions : [];
};

export const getAlgorithmById = (state: any, id: string) => {
  const length = state.algorithms.length;

  for (let i = 0; i < length; i += 1) {
    if (state.algorithms[i].id === id) {
      return state.algorithms[i];
    }
  }
};
