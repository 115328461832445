import en from "./en";

const Localization = { locale: "en-US" };

const languages: any = { en },
  defaultTexts = languages["en"];

export const getLanguage = (selectedLanguage: string) => {
  const locale = Localization.locale,
    baseLocale = locale.slice(0, 2),
    language = selectedLanguage
      ? selectedLanguage
      : languages[locale]
      ? locale
      : languages[baseLocale]
      ? baseLocale
      : "en";

  return language;
};

export const getLanguageText = (
  language: string,
  key: string,
  params?: any
) => {
  let message = getLanguageObject(language, key);

  if (message && "object" === typeof params) {
    const keys = Object.keys(params),
      length = keys.length;

    for (let i = 0; i < length; i++) {
      const key = keys[i],
        value = params[key],
        regex = new RegExp(`%${key}%`, "g");

      message = message.replace(regex, value);
    }
  }
  return message;
};

export const getLanguageObject = (language: string, key: string) => {
  const texts: any = languages[language] ? languages[language] : defaultTexts,
    text: string = texts[key]
      ? texts[key]
      : defaultTexts[key]
      ? defaultTexts[key]
      : "";

  if (!text) {
    console.warn("Invalid language key:", language, key);
    return "Invalid language key";
  }
  return text;
};
