import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { v4 as uuidv4 } from "uuid";
import {
  IonAlert,
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonList,
  IonListHeader,
  IonLoading,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter,
  useIonViewDidLeave,
  useIonViewWillEnter
} from "@ionic/react";
import { arrowUndoCircleOutline, refreshCircleOutline } from "ionicons/icons";
import {
  addAnswer,
  addSymptom,
  clearInput,
  clearSelection,
  clearUi,
  clearWs,
  getAlgorithm,
  getQuestion,
  goUiPrev,
  goWsPrev,
  goInputPrev,
  processInput,
  questionAsked,
  saveInputPrev,
  setLoading,
  saveUiPrev,
  saveWsPrev,
  setUiData,
  setVisited
} from "../actions";
import Algorithm from "../types/algorithm";
import Inference from "../types/inference";
import { getAlgorithmById } from "../reducers/ws";
import { getLanguageText } from "../Language";
import config from "../config";
import Bubble from "../components/Bubble";
import InferenceItem from "../components/InferenceItem";
import Footer from "../components/Footer";
import Options from "../containers/Options";

interface OwnProps extends RouteComponentProps<{ id: string; }> { }

interface ReduxProps {
  input: any;
  ui: any;
  ws: any;
  addAnswer: (questionAnswer: any) => any;
  addSymptom: (id: string) => any;
  clearInput: () => any;
  clearSelection: () => any;
  clearUi: () => any;
  clearWs: () => any;
  getAlgorithm: (ids: string, callBack?: (store: any) => any) => any;
  getQuestion: (ids: string[], callBack?: (store: any) => any) => any;
  goUiPrev: () => any;
  goWsPrev: () => any;
  goInputPrev: () => any;
  processInput: (input: any, callBack?: (store: any) => any) => any;
  questionAsked: (id: string) => any;
  saveInputPrev: () => any;
  saveUiPrev: () => any;
  saveWsPrev: () => any;
  setLoading: (value: boolean) => any;
  setUiData: (key: string, value: any) => any;
  setVisited: (id: string) => any;
}

interface RunAlgoProps extends OwnProps, ReduxProps { }

const RunAlgo: React.FC<RunAlgoProps> = ({
  match,
  input,
  ui,
  ws,
  addAnswer,
  addSymptom,
  clearInput,
  clearSelection,
  clearUi,
  clearWs,
  goUiPrev,
  goWsPrev,
  goInputPrev,
  getAlgorithm,
  getQuestion,
  processInput,
  questionAsked,
  saveInputPrev,
  saveUiPrev,
  saveWsPrev,
  setLoading,
  setUiData,
  setVisited
}) => {

  const { askedQuestions, language, loading, noAnswer, selectedOptions, showInference } = ui,
    { inferences, questions, workup } = ws,
    { symptoms, questionAnswers } = input,
    [appConfig, setAppConfig] = useState({ ...config.defaultAppConfig }),
    [id, setId] = useState(""),
    algorithm: Algorithm = ws.algorithm || getAlgorithmById(ws, id),
    askQuestions = workup
      .filter((q: any) => (!questionAnswers[q.id] && questions[q.id]))
      .map((q: any) => questions[q.id])
      .sort((q1: any, q2: any) => (q1.rank - q2.rank)),
    askQuestion = askQuestions.length ? askQuestions[0] : null,
    options = askQuestion ? [...askQuestion.answers].sort((a: any, b: any) => (a.sortOrder - b.sortOrder)) : [],
    undoEnabled = input.prevState || ui.prevState || ws.prevState,
    contentRef = useRef(null);

  const _start = (id: string) => {
    console.info("ID:", id);
    setUiData("evaluationId", uuidv4());
    setLoading(true);
    processInput({ symptoms: [{ id }] }, (store) => {
      const { ws } = store.getState();
      ws.workup.length && getQuestion(
        ws.workup.map((item: any) => item.id),
        () => {
          setLoading(false);
          _scrollToBottom();
        }
      )
    });
    addSymptom(id);
    getAlgorithm(id);
  };

  const _clear = () => {
    // order is important
    clearUi();
    clearInput();
    clearWs();
  };

  useIonViewWillEnter(() => {
    const appConfig = config.hostnameMap[window.location.hostname];
    let id = "";

    if (appConfig) {
      console.info("RulAlgo appConfig:", appConfig);
      setAppConfig(appConfig);
      id = appConfig.algorithm;
    } else {
      id = match.params.id
    }
    setId(id);
    _start(id);
  });

  useIonViewDidEnter(() => {
    setVisited(id);
  });

  useIonViewDidLeave(_clear);

  const _reset = () => {
    _clear();
    _start(id);
  }

  const _undo = () => {
    goUiPrev();
    clearSelection();
    goWsPrev();
    goInputPrev();
  };

  const _scrollToBottom = () => {
    // @ts-ignore
    contentRef && contentRef.current.scrollToBottom(500);
  }

  const _questionAnswered = (id: string, answerId?: string) => {
    const answers: any = {},
      answeredIds = Object.keys(selectedOptions).filter(aid => selectedOptions[aid]);

    if (answerId) {
      answeredIds.push(answerId);
    }
    if (answeredIds.length) {
      answeredIds.forEach(aid => {
        // handle the last click that is not yet reflected in the store, in case of exclusive question
        if (
          // not the answer that was last clicked, so respect the store value
          aid !== answerId ||
          // the answer that was last clicked, toggle the store value
          !selectedOptions[aid]
        ) {
          answers[aid] = 1;
        }
      });
      saveInputPrev();
      saveUiPrev()
      saveWsPrev();
      questionAsked(id);
      addAnswer({ id, answers });
      clearSelection();
      setLoading(true);
      processInput({ symptoms, questionAnswers: { ...questionAnswers, [id]: answers } }, (store) => {
        const { ws } = store.getState();
        if (ws.workup.length) {
          getQuestion(
            ws.workup.map((item: any) => item.id),
            () => {
              setLoading(false);
              _scrollToBottom();
            }
          )
        } else {
          setUiData("showInference", true);
          setLoading(false);
          setTimeout(_scrollToBottom, 100);
        }
      });
    } else {
      // no answer selected
      setUiData("noAnswer", true);
    }
  }

  return (
    <IonPage id="run-algo-page">
      <IonHeader>
        <IonToolbar>
          {!appConfig.algorithm &&
            <IonButtons slot="start">
              <IonBackButton defaultHref="/home"></IonBackButton>
            </IonButtons>
          }
          <IonTitle>{
            appConfig.algorithm ?
              appConfig.appName :
              algorithm ?
                algorithm.alias ?
                  algorithm.alias :
                  algorithm.name :
                getLanguageText(language, "loading")
          }</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={_reset}>
              <IonIcon slot="icon-only" icon={refreshCircleOutline} />
            </IonButton>
            <IonButton disabled={!undoEnabled} onClick={_undo}>
              <IonIcon slot="icon-only" icon={arrowUndoCircleOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent ref={contentRef}>
        {!!algorithm &&
          <IonHeader collapse="condense">
            <IonToolbar>
              <IonTitle size="large">
                {algorithm.name}
              </IonTitle>
            </IonToolbar>
          </IonHeader>
        }
        {!!askedQuestions.length &&
          askedQuestions.map((qid: string) =>
            <React.Fragment key={qid}>
              <Bubble type="system" text={questions[qid].name} />
              {
                questions[qid].answers
                  .filter((a: any) => !!questionAnswers[qid][a.id])
                  .map((a: any) => <Bubble type="user" text={a.name} />)
              }
            </React.Fragment>
          )
        }
        {!loading && !askedQuestions.length && !workup.length &&
          <Bubble type="system" text={getLanguageText(language, "noQuestions")} />
        }
        {showInference && !!inferences.length &&
          <IonList>
            <IonListHeader lines="full">
              <IonLabel>
                <h1>{getLanguageText(language, "inferences")}</h1>
              </IonLabel>
            </IonListHeader>
            {
              inferences.map((i: Inference, index: number) => <InferenceItem key={index} inference={i} />)
            }
          </IonList>
        }
        {!!askQuestion &&
          <>
            <Bubble type="system" text={askQuestion.name} />
            <Options
              data={options}
              exclusive={!!askQuestion.exclusive}
              onDone={answerId => {
                _questionAnswered(askQuestion.id, answerId);
              }}
            />
          </>
        }
        <IonLoading isOpen={loading} />
        <IonAlert
          isOpen={noAnswer}
          onDidDismiss={() => setUiData("noAnswer", false)}
          header={getLanguageText(language, "alert")}
          message={getLanguageText(language, "noAnswer")}
          buttons={[getLanguageText(language, "ok")]}
        />
      </IonContent>
      <Footer />
    </IonPage >
  );
};

const mapStateToProps = (state: any) => ({
  input: state.input,
  ui: state.ui,
  ws: state.ws
});

export default connect(
  mapStateToProps,
  {
    addAnswer,
    addSymptom,
    clearInput,
    clearSelection,
    clearUi,
    clearWs,
    goUiPrev,
    goWsPrev,
    goInputPrev,
    getAlgorithm,
    getQuestion,
    processInput,
    questionAsked,
    saveInputPrev,
    saveUiPrev,
    saveWsPrev,
    setLoading,
    setUiData,
    setVisited
  }
)(RunAlgo);
