import { combineReducers } from "redux";
import input from "./input";
import ws from "./ws";
import ui from "./ui";

const appReducer = combineReducers({
  input,
  ws,
  ui,
});

export default appReducer;
