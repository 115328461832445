import {
  INPUT_SET_DATA,
  INPUT_ADD_SYMPTOM,
  INPUT_ADD_ANSWER,
  INPUT_SAVE_PREV,
  INPUT_RETRIEVE_PREV,
  INPUT_CLEAR,
} from "../actions/input";
import { getInitialReducerState } from "../lib/utils";

const initial = {
  symptoms: [],
  questionAnswers: {},
};

const input = (state = getInitialReducerState(initial), action: any) => {
  const { type, key, value } = action;
  switch (type) {
    case INPUT_SET_DATA:
      return { ...state, [key]: value };
    case INPUT_ADD_SYMPTOM:
      return { ...state, symptoms: [...state.symptoms, action.symptom] };
    case INPUT_ADD_ANSWER:
      const { id, answers } = action.questionAnswer;
      return {
        ...state,
        questionAnswers: {
          ...state.questionAnswers,
          [id]: answers,
        },
      };
    case INPUT_SAVE_PREV:
      return {
        ...state,
        prevState: { ...state },
      };
    case INPUT_RETRIEVE_PREV:
      return state.prevState ? state.prevState : state;
    case INPUT_CLEAR:
      return getInitialReducerState(initial);
    default:
      return state;
  }
};

export default input;
